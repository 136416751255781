<template>
  <div>
    <b-button @click="login" class="mr-1">Login to Google Contact</b-button>

    <b-button @click="test_load">Test Load</b-button>

    <div class="my-2" v-if="redirect_uri">
      <a :href="redirect_uri">{{ redirect_uri }}</a>
      <hr />
    </div>

    <div v-if="code">
      {{ code }} <br />
      <b-button @click="sent_code" class="my-1">SENT TO API</b-button>
    </div>

    <div v-if="refresh_token">
      {{ refresh_token }}
    </div>
    test list <br />
    {{ test_list }}
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
export default {
  components: {
    BButton,
  },
  data() {
    return {
      redirect_uri: null,
      code: null,
      refresh_token: null,
      test_list: [],
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.code = urlParams.get("code");
  },
  methods: {
    async login() {
      this.$http({
        method: "POST",
        url: `/member/google-contact/init`,
      }).then((x) => {
        this.redirect_uri = x.data.data;
      });
    },

    async sent_code() {
      this.$http({
        method: "POST",
        url: `/member/google-contact/set-code`,
        data: {
          code: this.code,
        },
      }).then((x) => {
        console.log(x.data.data);
        this.refresh_token = x.data.data;
      });
    },

    async test_load() {
      this.$http({
        method: "POST",
        url: `/member/google-contact/test-load`,
        data: {
          code: this.code,
        },
      }).then((x) => {
        this.test_list = x.data.data;
      });
    },
  },
};
</script>
